<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group>
            <v-select
              v-model="filter.angkatan"
              :options="years"
              :reduce="(item) => item.value"
              placeholder="Tahun Ajaran"
              label="name"
              @input="handleChange"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <v-select
              v-model="filter.semester"
              :options="semesters"
              :reduce="(item) => item.value"
              placeholder="Semester"
              label="name"
              @input="handleChange"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col>
        <b-card title="Grafik Hasil Kuesioner Dosen">
          <bar-chart :data="graphic" :key="bar_key" />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <c-table-lecturer-summaries
          :title="title"
          :items="getItems"
          :columns="columns"
          :totalRows="totalRows"
          :perPage="perPage"
          :currentPage="currentPage"
          :api="api"
          :filterCourse="true"
          @changeCurrentPage="changeCurrentPage"
          @changePerPage="changePerPage"
        ></c-table-lecturer-summaries>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BRow,
  BCol,
} from "bootstrap-vue";
import LineChart from "@/components/LineChart.vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import table from "@/libs/table";
import PieChart from "@/components/PieChart.vue";
import DoughnutChart from "@/components/DoughnutChart.vue";
import CTableLecturerSummaries from "@/components/CTableLecturerSummaries.vue";
import vSelect from "vue-select";
import BarChart from "@/components/BarChart.vue";

export default {
  components: {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BRow,
    BCol,
    LineChart,
    PieChart,
    DoughnutChart,
    vSelect,
    BarChart,
    CTableLecturerSummaries,
  },
  data() {
    const permission = this.$store.getters["app/ACTIONS"];
    return {
      bar_key: 0,
      table_key: 0,
      title: "Kuesioner",
      api: `questionnaire-students/list-questionnaire-block`,
      graphic_api: `questionnaire-answer/chart-questionnaire`,
      graphic: {},
      dir: false,
      items: [],
      filter: {
        angkatan: null,
        semester: null,
      },
      totalRows: 0,
      perPage: 10,
      limit: 0,
      orderBy: "createdAt",
      sortBy: "desc",
      currentPage: 1,
      search: "",
      years: [],
      semesters: [
        {
          name: "Semua",
          value: null,
        },
        {
          name: "Ganjil",
          value: "ganjil",
        },
        {
          name: "Genap",
          value: "genap",
        },
      ],
      columns: [
        { key: "no", label: "#", sortable: false },
        { key: "blockkeyname", label: "nama blok", sortable: true },
        { key: "year", label: "tahun ajaran", sortable: true },
        { key: "semester", label: "semester", sortable: true },
        {
          key: "action",
          label: "aksi",
          class: "text-center",
          actions: { show: Boolean(permission.show) },
        },
      ],
    };
  },
  async mounted() {
    const _ = this;
    _.graphic = await this.getData();
    _.years = await this.getYears();
  },
  methods: {
    async getData() {
      const _ = this;
      let data;
      await _.$axios.get(_.graphic_api, { params: this.filter }).then((res) => {
        data = res.data.data;
      });
      return data;
    },
    getItems(ctx, callback) {
      table.getItems(ctx, callback, this);
    },
    changeCurrentPage(page) {
      table.changeCurrentPage(this, page);
    },
    changePerPage(perPage) {
      table.changePerPage(this, perPage);
    },
    async getYears() {
      const _ = this;
      let data;
      let result = [];
      await _.$axios.get("misc/years").then((res) => {
        data = res.data;
      });
      result = [...result, { name: "Semua", value: null }];
      data.forEach((element) => {
        result = [
          ...result,
          {
            name: element.year,
            value: element.year,
          },
        ];
      });
      return result;
    },
    async handleChange() {
      this.graphic = await this.getData();
      this.bar_key += 1;
    },
  },
};
</script>
