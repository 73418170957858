<template>
  <div>
    <e-charts
      ref="line"
      autoresize
      :options="option"
      theme="theme-color"
      @click="getModal"
      auto-resize
    />
    <b-modal size="xl" ref="detail-chart" ok-only ok-title="close">
      <b-card>
        <b-table responsive :fields="columns" :items="rows">
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
        </b-table>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BModal,
  BButton,
  VBModal,
  BAlert,
  BTable,
  BRow,
  BCol,
} from "bootstrap-vue";
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/bar";
import theme from "./theme.json";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    BCard,
    ECharts,
    BModal,
    VBModal,
    BButton,
    BAlert,
    BTable,
    BRow,
    BCol,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      id: "",
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          bottom: "0",
        },
        grid: this.optionData.grid,
        xAxis: this.optionData.xAxis,
        yAxis: this.optionData.yAxis,
        series: this.optionData.series,
      },
      api: "questionnaire-answer/detail-chart-questionnaire",
      filter: {},
      rows: [],
      columns: [
        {
          label: "No",
          key: "index",
        },
        {
          label: "NIP",
          key: "lecturer_nip",
        },
        {
          label: "Nama",
          key: "lecturer_name",
        },
        {
          label: "Nama Blok",
          key: "blockkeyname",
        },
        {
          label: "Skor",
          key: "answer",
        },
      ],
    };
  },
  methods: {
    async getData() {
      let data;
      const _ = this;
      await _.$axios.get(_.api, { params: this.filter }).then((res) => {
        data = res.data.data;
      });
      return data;
    },
    async getModal(params) {
      this.filter = {
        semester: params.data.semester,
        angkatan: params.data.angkatan,
        questionId: params.data.question_id,
      };
      this.rows = await this.getData();
      this.$refs["detail-chart"].show();
    },
    getItems(ctx, callback) {
      table.getItems(ctx, callback, this);
    },
    changeCurrentPage(page) {
      table.changeCurrentPage(this, page);
    },
    changePerPage(perPage) {
      table.changePerPage(this, perPage);
    },
  },
};
</script>
