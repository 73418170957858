<template>
  <div>
    <b-card title="Table Kitchen Sink" no-body>
      <b-modal
        id="modal-import"
        hide-backdrop
        no-close-on-backdrop
        content-class="shadow"
        title="Form Import"
        ok-title="Simpan"
        cancel-title="Batal"
        @ok="handleOkImport"
        @show="handleShowImport"
      >
        <form ref="form" @submit.stop.prevent="handleSubmitImport">
          <b-form-group
            label="File"
            label-for="file-input"
            invalid-feedback="File harus di isi"
            :state="fileState"
          >
            <b-form-file
              v-model="file"
              :state="fileState"
              required
              accept=" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </form>
      </b-modal>

      <b-card-body>
        <h3>{{ title }}</h3>
      </b-card-body>

      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap mb-2">
          <div>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              v-if="actions.add"
              class="mr-1 float-left"
            >
              <feather-icon icon="DatabaseIcon" class="mr-50" />
              <span class="align-middle">Tambah</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-import
              v-if="actions.import"
              variant="outline-info"
              class="mr-1 float-left"
            >
              <feather-icon icon="UploadIcon" class="mr-50" />
              <span class="align-middle">Impor</span>
            </b-button>
          </div>
        </div>

        <b-row class="mb-0">
          <b-col cols="3">
            <b-form-group v-if="actions.filter.course">
              <v-select
                v-model="filter.blockKeyId"
                :options="courses"
                :reduce="(item) => item.id"
                placeholder="Nama Blok"
                label="name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group v-if="actions.filter.course">
              <v-select
                v-model="filter.year"
                :options="years"
                :reduce="(item) => item.year"
                placeholder="Tahun Ajaran"
                label="year"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group v-if="actions.filter.course">
              <v-select
                v-model="filter.semester"
                :options="semester"
                :reduce="(item) => item.value"
                placeholder="Semester"
                label="value"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label-cols-sm="5"
              label-align-sm="left"
              label-size="md"
              label-for="filterInput"
            >
              <b-input-group size="md" class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  placeholder="Cari"
                  id="filterInput"
                  v-model="filter.search"
                  type="search"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="columnOptions"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        ref="cTableStudyPlan"
        show-empty
      >
        <template #empty="" style="text-align: center">
          Tidak ada data
        </template>

        <template #cell(no)="data">
          {{ (currentPage - 1) * perPage + (data.index + 1) }}
        </template>
        <template #cell()="data">
          <i>{{ data.value }}</i>
        </template>
        <template #cell(status)="data">
          <b-badge pill variant="warning" v-if="data.value === 'unpaid'"
            >Belum Bayar</b-badge
          >
          <b-badge pill variant="success" v-else>Sudah Bayar</b-badge>
        </template>

        <template #cell(action)="data" class="text-center">
          <div style="min-width: 270px">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-popover.hover.bottom="'Detail'"
              size="sm"
              class="btn-icon float-sm-center mr-1"
              v-if="actions.show"
              @click="onShow(data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              v-b-popover.hover.bottom="'Ubah'"
              size="sm"
              class="btn-icon float-sm-center mr-1"
              v-if="actions.edit"
              @click="onEdit(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              v-b-popover.hover.bottom="'Hapus'"
              size="sm"
              class="btn-icon float-sm-center mr-1"
              v-if="actions.destroy"
              @click="onDelete(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              v-b-popover.hover.bottom="'Cetak'"
              size="sm"
              class="btn-icon float-sm-center mr-1"
              v-if="actions.print"
              @click="onPrint(data.item.id)"
            >
              <feather-icon icon="PrinterIcon" />
            </b-button>
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="md"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="onTablePerPage"
            @change="changePerPage"
            size="md"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <div>
          <b-pagination
            v-model="onTableCurrentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            prev-class="prev-item"
            next-class="next-item"
            last-number
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>

    <b-modal size="xl" ref="detail-table" ok-only ok-title="close">
      <b-card>
        <b-table
          responsive
          :fields="lecturerBlockField"
          :items="lecutererBlocks"
        >
          <template #cell(no)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(action)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-popover.hover.bottom="'Detail'"
              size="sm"
              class="btn-icon float-sm-center mr-1"
              v-if="actions.show"
              @click="detail(data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </template>
        </b-table>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  BCardBody,
  VBPopover,
  VBModal,
  BModal,
  BAlert,
  BFormFile,
  BRow,
  BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BCardBody,
    BCard,
    Ripple,
    VBPopover,
    VBModal,
    BModal,
    BAlert,
    BFormFile,
    ToastificationContent,
    BRow,
    BCol,
    vSelect,
  },
  directives: {
    Ripple,
    "b-popover": VBPopover,
    "b-modal": VBModal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Function,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
      default: () => [],
    },
    totalRows: {
      type: Number,
      required: true,
      default: () => 0,
    },
    currentPage: {
      type: Number,
      required: true,
      default: () => 0,
    },
    perPage: {
      type: Number,
      required: true,
      default: () => 0,
    },
    api: {
      type: String,
      required: true,
    },
    apiDelete: {
      type: String,
    },
    apiImport: {
      type: String,
    },
    add: {
      type: Boolean,
    },
    import: {
      type: Boolean,
    },
    export: {
      type: Boolean,
    },
    exportFile: {
      type: String,
    },
    filterCourse: {
      type: Boolean,
    },
  },
  data() {
    return {
      pageOptions: [10, 25, 50],
      onTablePerPage: this.$props.perPage,
      onTableCurrentPage: this.$props.currentPage,
      sortBy: "createdAt",
      sortDesc: true,
      sortDirection: "desc",
      filter: {
        search: null,
        blockKeyId: null,
        year: null,
        semester: null,
      },
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      actions: {
        add: Boolean(this.$props.add),
        import: Boolean(this.$props.import),
        export: Boolean(this.$props.export),
        filter: {
          course: Boolean(this.$props.filterCourse),
        },
        show: false,
        edit: false,
        destory: false,
        print: false,
      },
      file: null,
      fileState: null,
      courses: [],
      years: [],
      semester: [],
      lecturerBlockField: [
        { key: "no", label: "#", sortable: false },
        { key: "lecturer.nip", label: "NIP", sortable: true },
        { key: "lecturer.name", label: "Nama", sortable: true },
        { key: "block.blockKey.name", label: "Nama Blok", sortable: true },
        {
          key: "action",
          label: "aksi",
          class: "text-center",
        },
      ],
      lecutererBlocks: [],
      modalApi: 'lecturer-blocks/list-lecturers-questionnaire',
    };
  },
  computed: {
    sortOptions() {
      return this.columns
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    columnOptions() {
      return this.columns.map((column) => {
        if (column.actions) {
          this.actions = { ...this.actions, ...column.actions };
          delete column.actions;
        }

        return column;
      });
    },
  },
  created() {
    const _ = this;
    _.getCourses();
    _.getYears();
    _.getSemester();
  },
  mounted() {},
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {},
    changePerPage() {
      this.$emit("changePerPage", this.onTablePerPage);
    },
    onShow(item) {
      const _ = this;
      _.$axios.get(`${_.modalApi}/${item.blockid}`).then((res) => {
        _.lecutererBlocks = res.data.data;
        this.$refs["detail-table"].show();
      })
    },
    onPrint() {},
    handleOkImport(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmitImport();
    },
    handleShowImport() {
      this.file = null;
      this.fileState = null;
    },
    checkFormValidityImport() {
      const valid = this.$refs.form.checkValidity();
      this.fileState = valid;
      return valid;
    },
    handleSubmitImport() {
      const _ = this;
      if (!_.checkFormValidityImport()) {
        return;
      }
      const formData = new FormData();
      formData.append("file", _.file, _.file.name);
      _.$axios.post(`${_.$props.apiImport}`, formData, {}).then((res) => {
        const consume = res.data;
        if (consume.statusCode === 201) {
          _.toastNotification(
            "success",
            "BellIcon",
            `Berhasil menambahkan data.`
          );
          _.$refs.cTableStudyPlan.refresh();
        }
      });
      _.$nextTick(() => {
        _.$bvModal.hide("modal-import");
      });
    },
    toastNotification(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Pemberitahuan",
          icon,
          text,
          variant,
        },
      });
    },
    getCourses() {
      const _ = this;
      _.$axios.get(`misc/blocks`).then((res) => {
        _.courses = res.data;
      });
    },
    getYears() {
      const _ = this;
      _.$axios.get(`misc/years`).then((res) => {
        _.years = res.data;
      });
    },
    getSemester() {
      const _ = this;
      _.semester = [
        {
          value: "Ganjil",
        },
        {
          value: "Genap",
        },
      ];
    },
    detail(item) {
      this.$store.commit('summarySession/UPDATE_USER', {
        name: item.lecturer?.name,
        nip: item.lecturer?.nip,
        block: item.block?.block,
        course: item.block?.course?.name
      })
      this.$router.push(
        `${this.$route.path}/${item.lecturer?.id}/${item.block?.id}`
      );
    },
  },
  watch: {
    onTableCurrentPage: function (newValue) {
      let _ = this;
      _.$emit("changeCurrentPage", newValue);
    },
    items: function (newValue) {
      // console.log(newValue);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
